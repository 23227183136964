form div .Login-button{
    width: 100%;
    height: 50px;
    text-transform: none;
    font-family: 'Roboto',Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1.2em;
    line-height: 23px;
    text-align: center;
    color: #FFFFFF;
}
.Login-senha-input,.Login-div-btn-entrar{
    width: 100%;
}
form .Login-senha-input{
    margin-bottom: 1.2rem;
}
form .Login-input-color input{
    background-color: white;
}