.historico-user-input-data{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 65%;
    margin-left: 2rem;
}
.historico-user-input-data .date{
    margin-right: 1rem;
    width: 80%;
}
.historico-user-envolve-busca .buscaHistorico{
    width: 35%;
}
.historico-user-envolve-busca{
    display: flex;
    align-items: center;
}
.historico-user-div-msg{
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.hisotrico-user-icon{
    cursor: pointer;
}
.historico-user-table .MuiTableCell-alignRight {
        text-align: left;
        flex-direction: row-reverse;
}
.ml-btn-1{
    margin-left: 1rem !important;
}
.historico-user-table {
    background-color: #fff;
    padding: 1rem;
    border-radius: 10px;
}
.historico-user-semUsuario{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.historico-user-semUsuario h1{
    font-size: 1.3em;
    margin-bottom: 0;
}
.envolve-btn-filtro-user{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}
.envolve-btn-filtro-user .color-secondary{
    margin-left: 1rem;
}
.btn-edit-licenca span{
    font-size: .9em;
    text-transform: initial;
}
.cabecalho-personalizado{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}
.cabecalho-personalizado h1{
    margin: 0;
    color: #8f8f8f;
}
.cabecalho-personalizado .color-secondary{
    margin-left: 1rem;
}
.tabela-personalizada{
    background-color: white;
    padding: 2rem;
    padding-bottom: 0rem;
    border-radius: 6px;
}
.MuiTablePagination-root{
    background-color: white;
}
.historico-user-table{
    border-radius: 0;
}
.tabela-personalizada .MuiPaper-root.MuiPaper-rounded{
    box-shadow: 0px 0px 0px 0px gray !important;
}
.MuiButtonBase-root.MuiButton-root.MuiButton-contained.color-primary.btn-edit-licenca.MuiButton-containedPrimary,
.MuiButtonBase-root.MuiButton-root.MuiButton-contained.color-secondary.btn-edit-licenca.MuiButton-containedSecondary{
    max-width: 162px;
    min-width: 162px;
}
.tabela-personalizada .MuiTableBody-root{
    overflow: auto;
    max-height: 195px;
    min-height: 100px;
}
.modal-Add-licenca .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-rounded{
    min-width: 600px !important;
    padding-left: .5rem;
    padding-right: .5rem;
}
.modal-Add-licenca .MuiDialogTitle-root{
    padding: 0rem;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: .5rem;
}
.modal-Add-licenca .MuiDialogContent-root{
    padding: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.tabelaTel{
    white-space: nowrap;
}
.tabelaEmail{
    text-align: center !important;
}
.circular-edit-form svg{
    color: #27b8d2 !important;
}
.circular-edit-form{
    display: flex;
    align-items: center;
    justify-content: center;
}