.loadCircular div .MuiCircularProgress-root {
    color: #ffffff;
    width: 30px !important;
    height: 30px !important;
}

.btnModalEsqueciSenha .loadCircular div .MuiCircularProgress-root,
.GridMensagem-envolve-conteudo .loadCircular div .MuiCircularProgress-root {
    color: #f50057;
}

.GridMensagem-envolve-conteudo .loadCircular {
    display: flex;
    justify-content: center;
}