.NovoModelo-envolve-laterais{
    height: 100vh;
    background-color: white;
    overflow-y: auto;
    position: relative;
}
.NovoModelo-InfoProntuario{
    padding: 2rem;
}
.NovoModelo-InfoProntuario-FormPront{
    width: 100%;
    background-color: #f9f9f9;
    padding: 1rem;
    margin-top: 3rem;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.NovoModelo-InfoProntuario-nomePront{
    width: 100%;
    margin-left: 1rem;
    color: #8f8f8f;
    font-size: 1.2em;
    font-weight: 500;
    margin-top: .5rem;
    margin-bottom: .5rem;
}
.NovoModelo-InfoProntuario-Secao{
    width: 100%;
    background-color: #f9f9f9;
    padding: 1rem;
    margin-top: 2rem;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
}
.envolve-secao-icon{
    display: flex;
    justify-content: center;
    align-items: center;
}

.envolve-titulo-secao{
    background-color: white;
    padding: .8rem;
    color: #676767;
    font-weight: 400;
    font-size: 1em;
    box-shadow: 0px 0px 6px 0px #d0d0d0;
    border-radius: 4px;
    width: 100%;
    cursor: grab;
}
.envolve-titulo-secao p{
    margin: 0;
}
.icon-list{
    color: #868686;
    font-size: 2.3em;
    margin-right: .5rem;
    cursor: default;
}
.NovoModelo-Secao-btn-add{
    width: 100%;
}
.btn-add-secao{
    text-align: center;
    width: 100%;
    display: flex !important;
    justify-content: flex-end !important;
    margin-bottom: .7rem !important;
}
.NovoModelo-Ferramentas-princ{
    margin-top: 3rem;
    padding: 2rem;
    flex-wrap: wrap;
    display: flex;
    padding-right: 1rem;
}
.NovoModelo-Ferramentas{
    width: 7.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #f9f9f9;
    border-radius: 6px;
    height: 7.4rem;
    box-shadow: 0px 0px 6px 0px #b4b4b4;
    margin: .6rem;
}
.circle-icon{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: gray;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}
.titulo-ferramenta-btn{
    margin-bottom: 0;
    color: grey;
}
.envolve-btn-add-ferramentas{
    position: absolute;
    padding-right: 6.7rem;
    padding-top: 6.5rem;
}
.btn-add-ferramenta{
    background-color: #27b8d2 !important;
    color: white !important;
    width: 2rem;
    height: 2rem;
}
.SecaoInput-container{
    margin-top: 4rem;
    padding: 2rem;
}
.SecaoInput-container ul{
    padding: 0;
}
.divisor{
    width: 1px;
    height: 1.5rem;
    background-color: rgb(170, 170, 170);
}
.Card-option{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 97%;
}
.h3-title-card-input{
    margin-left: .5rem;
    color: #27b8d2;
}
.container-card-input{
    background-color: white;
    padding: 1rem;
    border-radius: 4px;
    box-shadow: 0px 0px 6px 0px rgb(201, 201, 201);
    margin-top: 1rem;
    width: 100%;
}
.icon-list-card-input{
    font-size: 2.5em !important;
    color: grey !important;
    margin-right: .5rem !important;
    cursor: pointer !important;
}
.CardTextoCurto-container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.label-color{
    color: #777777;
}
.background-input{
    background-color: white;
}
.Color-btn-salvarModelo{
    background-color: #27b8d2;
}
.Color-btn-salvarModelo:hover{
    background-color: #24acc4
}
.Color-btn-disabled{
    background-color: #777777;
}
.Color-btn-cancelarModelo{
    background-color: #E7366A !important;
    color: white !important;
}
.envolve-secao-icon{
    margin-bottom: 1rem;
}
.envolve-secoes{
    width: 100%;
}
.envolve-secoes ul{
    padding: 0;
}
.number-icon{
    font-size: 25px;
}

.envolve-secao-icon .MuiSvgIcon-root{
    cursor: grab !important;
}

/* [data-rbd-drag-handle-context-id="1"]{
    cursor: default !important;
} */

.react-icon{
    font-size: 1.5rem;
    margin-left: -4px;
}