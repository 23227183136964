body{
    margin: 0;
    padding: 0;
}
::-webkit-scrollbar-track {
    background-color: #F4F4F4;
}
::-webkit-scrollbar {
    width: 8px;
    background: #F4F4F4;
}
::-webkit-scrollbar-thumb {
    background: #b9b8b8;
}
.color-secondary{
    background-color: #f50057 !important;
    color: #ffffff !important;
}
.color-secondary:hover{
    background-color: #c51162 !important;
}
.color-secondaryD{
    background-color: #FFFFFF !important;
    color: #8F8F8F;
}
.color-secondaryD:hover{
    background-color: #fafafa !important;
}
.color-primary{
    background-color: #27b8d2 !important;
    color: #ffffff !important;
}
.color-primary:hover{
    background-color: #24acc4 !important;
}   
.MuiPaper-root.MuiAppBar-root.MuiAppBar-positionStatic.MuiAppBar-colorPrimary{
    box-shadow: 0px 0px 0px 0px gray !important;
}
.MuiSvgIcon-root.iconSearchColor{
    cursor: pointer
}